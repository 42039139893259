import {
  type Packet,
  type InfoCard,
  type PacketVariant,
  PACKET_THEMES,
  type UpcomingSubject,
} from "~/types/universe";
import type { Page, ItemRelation } from "~/utils/crystallize";
import type { Crystallize } from "~/crystallize/spec";
import { SUBJECT_CODES } from "~/types/core/common";

export function toPacket(child: Page | ItemRelation): Packet {
  const subscription: Packet = {
    type: "packet",
    id: child.id,
    name: "",
    title: "",
    cover: { img: "", alt: "" },
    discount: false,
    variants: [],
    formats: ["nettsted"],
    tax: 0,
    videoAdv: { title: "", url: "" },
    relatedArticles: [],
    slug: pathToSlug(child.path ?? ""),
    theme: "green-seagreen",
  };

  if (child.components) {
    for (const component of child.components) {
      if (!component.content) {
        continue;
      }

      const id = component.id as Crystallize.ComponentId<"aunivers-abonnement">;

      switch (id) {
        case "forsidebilde":
          subscription.coverCard = getSingleImage(component, 500);
          break;
        case "logo":
          subscription.logo = getSingleImage(component, "raw");
          break;
        case "navn": {
          const name = getSingleLine(component);
          subscription.name = name;
          subscription.slug.custom = slugify(name);
          break;
        }
        case "tittel":
          subscription.title = getSingleLine(component);
          break;
        case "undertittel":
          subscription.secondTitle = getSingleLine(component);
          break;
        case "trinn":
          subscription.grades = getEnumValues(component, GRADES, ["aarstrinn"]);
          subscription.levels = subscription.grades
            .map((it) => GRADE_TO_LEVEL[it])
            .filter(truthyAndDistinct);
          break;
        case "videoadv": {
          for (const chunk of getSingleChunks(component)) {
            if (!chunk.content) {
              continue;
            }

            const id = chunk.id as Crystallize.ChunkId<
              "aunivers-abonnement",
              "videoadv"
            >;

            switch (id) {
              case "tittel":
                subscription.videoAdv.title = getSingleLine(chunk);
                break;
              case "url":
                subscription.videoAdv.url = getSingleLine(chunk);
                break;
            }
          }
          break;
        }
        case "fargetema": {
          const selection = getSelections(component, "value")[0];
          const theme = PACKET_THEMES.find((it) => it === selection);
          if (theme) {
            subscription.theme = theme;
          }
          break;
        }
        case "fargekort":
          subscription.cardColor = getSelections(
            component,
            "value",
          )[0] as Crystallize.PacketCardColor;
          break;
        case "laeremiddelfag":
          subscription.upcomingSubjects = getRepeatableChunks(component)
            .map((subjectChunk, i) => {
              const subject: UpcomingSubject = {
                id: String(i),
              };

              for (const chunk of subjectChunk) {
                if (!chunk.content) {
                  continue;
                }

                const id = chunk.id as Crystallize.ChunkId<
                  "aunivers-abonnement",
                  "laeremiddelfag"
                >;

                switch (id) {
                  case "tittel":
                    subject.title = getSingleLine(chunk);
                    break;
                  case "beskrivelse":
                    subject.description =
                      getRichText(chunk) || getSingleLine(chunk);
                    break;
                  case "illustrasjon":
                    subject.illustration = getSingleImage(chunk);
                    break;
                }
              }

              return subject;
            })
            .filter(truthy);
          break;
        case "informasjonskort": {
          subscription.infoCards = getItemRelations(component).map((item) => {
            const infoCard: InfoCard = {
              id: item.id,
              title: "",
              subTitle: "",
              text: "",
              color: "",
              order: 0,
            };

            if (item.components) {
              for (const itemComponent of item.components) {
                if (itemComponent.content === null) {
                  continue;
                }

                const id = itemComponent.id as Crystallize.ComponentId<
                  Crystallize.ItemRelationId<
                    "aunivers-abonnement",
                    "informasjonskort"
                  >
                >;

                switch (id) {
                  case "tittel":
                    infoCard.title = getSingleLine(itemComponent);
                    break;
                  case "undertittel":
                    infoCard.subTitle = getSingleLine(itemComponent);
                    break;
                  case "tekst":
                    infoCard.text = getRichText(itemComponent);
                    break;
                  case "rekkefolge":
                    infoCard.order =
                      parseInt(getSingleLine(itemComponent)) ?? 0;
                    break;
                  case "farge": {
                    const color = getSelections(itemComponent, "value")[0];
                    if (color) {
                      infoCard.color = color;
                    }
                    break;
                  }
                  case "cta": {
                    const repeatableChunks = getRepeatableChunks(itemComponent);
                    const ctaData = {
                      label: "",
                      url: "",
                      color: "",
                      graphic: false,
                      isbn: "",
                    };
                    for (const chunks of repeatableChunks) {
                      for (const chunk of chunks) {
                        if (chunk.content === null) {
                          continue;
                        }

                        const id = chunk.id as Crystallize.ChunkId<
                          "informasjonskort",
                          "cta"
                        >;

                        switch (id) {
                          case "label":
                            ctaData.label = getSingleLine(chunk);
                            break;
                          case "url":
                            ctaData.url = getSingleLine(chunk);
                            break;
                          case "farge": {
                            const color = getSelections(chunk, "value")[0];
                            if (color) {
                              ctaData.color = color;
                            }
                            break;
                          }
                          case "gratis-grafikk":
                            ctaData.graphic = getBoolean(chunk);
                            break;
                          case "isbn-trial":
                            ctaData.isbn = getSingleLine(chunk);
                            break;
                        }
                      }
                    }
                    infoCard.cta = ctaData;
                    break;
                  }
                  case "grafikk": {
                    infoCard.graphics = getRepeatableChunks(itemComponent).map(
                      (chunks) => {
                        const graphicItem = { url: "", position: "" };
                        for (const chunk of chunks) {
                          if (!chunk.content) {
                            continue;
                          }

                          const id = chunk.id as Crystallize.ChunkId<
                            "informasjonskort",
                            "grafikk"
                          >;

                          switch (id) {
                            case "bilde":
                              graphicItem.url =
                                getSingleImage(chunk)?.img ?? "";
                              break;
                            case "posisjon": {
                              const position = getSelections(chunk, "value")[0];
                              if (position) {
                                graphicItem.position = position;
                              }
                              break;
                            }
                          }
                        }
                        return graphicItem;
                      },
                    );
                    break;
                  }
                }
              }
            }

            return infoCard;
          });

          subscription.infoCards.sort((a, b) => a.order - b.order);

          break;
        }

        case "kampanje": {
          const itemRelation = getItemRelations(component)?.[0];
          if (itemRelation) {
            subscription.campaign = toCampaign(itemRelation);
          }
          break;
        }

        case "related-articles": {
          subscription.relatedArticles =
            getItemRelations(component).map(toArticle);
          break;
        }

        case "product-cta": {
          for (const chunk of getSingleChunks(component)) {
            if (!chunk.content) {
              continue;
            }

            const id = chunk.id as Crystallize.ComponentId<
              Crystallize.ItemRelationId<"aunivers-abonnement", "product-cta">
            >;

            subscription.productCta ??= {};

            switch (id) {
              case "title":
                subscription.productCta.title = getSingleLine(chunk);
                break;
              case "text":
                subscription.productCta.text = getRichText(chunk);
                break;
            }
          }
          break;
        }
      }
    }
  }

  subscription.tax =
    ("vatType" in child && child.vatType && child.vatType.percent) || 0;

  if ("variants" in child && Array.isArray(child.variants)) {
    subscription.variants = child.variants.map<PacketVariant>((variant) => {
      const cover = variant.images?.[0];
      const prices = getPrices(variant.priceVariants ?? [], variant.price);

      const subscriptionVariant: PacketVariant = {
        parentSlug: subscription.slug,
        parentType: subscription.type,
        parentTitle: subscription.title,
        sku: variant.sku,
        name: variant.name,
        isbn: variant.sku,
        price: prices.default,
        priceDiscount: prices.discount,
        priceNoTax: prices.exVat,
        priceWithTax: prices.incVat,
        included: false,
        relatedSubscription: [],
        fixedAmount: false,
        tax: 0,
        isTrial: false,
        subscriptionsPlan: variant.subscriptionPlans
          ? handleSubscriptionPlans(variant.subscriptionPlans)
          : undefined,
        cover: cover?.url
          ? {
              img: cover.url,
              alt: cover.altText ?? "",
            }
          : undefined,
      };

      if (variant.components) {
        for (const component of variant.components) {
          const id =
            component.id as Crystallize.VariantComponentId<"aunivers-abonnement">;

          switch (id) {
            case "navn":
              subscriptionVariant.customName = getSingleLine(component);
              break;
            case "tittel":
              subscriptionVariant.title = getSingleLine(component);
              break;
            case "subtitle":
              subscriptionVariant.subtitle = getSingleLine(component);
              break;
            case "subjects": {
              subscriptionVariant.subjects ??= [];
              subscriptionVariant.subjects.push(
                ...getEnumValues(component, SUBJECT_CODES),
              );
              break;
            }
            case "effective-vat-percent":
              subscriptionVariant.tax =
                subscription.tax ?? Number(getSingleLine(component));
              break;
            case "componenttype":
              subscriptionVariant.isTrial =
                getSingleLine(component).toLowerCase() === "provelisens";
              /* TODO: This would break current functionality, but would also be correct. Investigate how to achieve this.
              subscriptionVariant.formats = getEnumValues(
                component,
                PRODUCT_FORMATS,
              );
                */
              // if component-type is "Provelisens" then it is a trial and does not show as card in productlist
              break;
            case "isbn": {
              const isbn = getSingleLine(component)?.trim();
              if (isbn) {
                subscriptionVariant.isbn = isbn;
              }
              break;
            }
            case "beskrivelse":
              subscriptionVariant.description = getRichText(component);
              break;
            case "trinn":
              subscriptionVariant.grades = getEnumValues(component, GRADES, [
                "aarstrinn",
              ]);
              subscriptionVariant.levels = subscriptionVariant.grades
                .map((it) => GRADE_TO_LEVEL[it])
                .filter(truthyAndDistinct);
              subscriptionVariant.school = getSchool(subscriptionVariant);
              break;
            case "fast-tilganger":
              subscriptionVariant.fixedAmount = getBoolean(component);
              break;
            case "tilknyttet":
              subscriptionVariant.included = getBoolean(component);
              break;
            case "tilknyttende-abonnement": {
              const relatedSubscription = getRepeatableChunks(component);
              subscriptionVariant.relatedSubscription = relatedSubscription
                .map((related) => {
                  const value = related[0];
                  return value ? getSingleLine(value) : "";
                })
                .filter(Boolean);
              break;
            }
          }
        }
      }

      if (subscriptionVariant.isTrial) {
        subscriptionVariant.fixedAmount = true;
      }

      subscriptionVariant.isbn ||= subscriptionVariant.sku;

      return subscriptionVariant;
    });
  }

  return subscription;
}
