import { type Page } from "~/utils/crystallize";
import {
  type SingleProduct,
  type SingleProductVariant,
  SINGLE_PRODUCT_TEMPLATES,
  SINGLE_PRODUCT_THEMES,
  type Language,
  LANGUAGES,
  type HeroCardContent,
} from "~/types/single-product";
import { SUBJECT_CODES } from "~/types/core/common";
import type { Crystallize } from "~/crystallize/spec";

export function toProduct(item: Page): SingleProduct {
  // Template is added later, either from the components or inferred from other field values
  const productToStore: Omit<SingleProduct, "template"> = {
    id: item.id,
    name: item.name ?? undefined,
    type: "single-product",
    intro: "",
    description: "",
    theme: "green",
    discount: false,
    variants: [],
    formats: [],
    biblioFormats: [],
    relatedProducts: [],
    slug: pathToSlug(item.path ?? ""),
    pendelCta: false,
  };

  let template: Crystallize.SingleProductTemplate | undefined;
  if (item.components) {
    for (const component of item.components) {
      const id = component.id as Crystallize.ComponentId<"default-product">;

      switch (id) {
        case "tittel":
          productToStore.title = getSingleLine(component);
          productToStore.slug.custom = slugify(productToStore.title);
          break;
        case "template": {
          const selection = getSelections(component, "value");
          template = SINGLE_PRODUCT_TEMPLATES.find((t) =>
            selection.includes(t),
          );
          break;
        }
        case "summary":
          productToStore.intro = getRichText(component);
          break;
        case "beskrivelse":
          productToStore.description = getRichText(component);
          break;
        case "coverbilde-vgs":
          productToStore.cover = getSingleImage(component, "raw");
          break;
        case "kortbilde":
          productToStore.coverCard = getSingleImage(component, 500);
          break;
        case "fargetema": {
          const selection = getSelections(component, "value")[0];
          const theme = SINGLE_PRODUCT_THEMES.find((it) => it === selection);
          if (theme) {
            productToStore.theme = theme;
          }
          break;
        }
        case "fargekort":
          productToStore.cardColor = getSelections(
            component,
            "value",
          )[0] as Crystallize.SingleProductCardColor;
          break;
        case "egendefinerte-farger-pa-varianter":
          productToStore.selfDefinedColorVariant = getBoolean(component);
          break;
        case "informasjonskort": {
          for (const chunks of getRepeatableChunks(component)) {
            let content: HeroCardContent | undefined;

            for (const value of chunks) {
              if (value) {
                content ??= {};

                const id = value.id as Crystallize.ChunkId<
                  "default-product",
                  "informasjonskort"
                >;

                switch (id) {
                  case "informasjonskort":
                    content.text = getSingleLine(value);
                    break;
                  case "background-color":
                    content.backgroundColor = getSingleLine(value);
                    break;
                  case "text-color":
                    content.textColor = getSingleLine(value);
                    break;
                }
              }
            }

            if (content) {
              productToStore.heroCards ??= {
                content: [],
                mascotImages: [],
                colorTheme: "white-green",
              };

              productToStore.heroCards.content.push(content);
            }
          }
          break;
        }
        case "maskott": {
          const mascot = getItemRelations(component)[0];
          if (mascot?.components) {
            for (const c of mascot.components) {
              if (!c.content) {
                continue;
              }

              const images = getImages(c);
              for (const image of images) {
                if (image) {
                  productToStore.heroCards ??= {
                    content: [],
                    mascotImages: [],
                    colorTheme: "white-green",
                  };
                  productToStore.heroCards.mascotImages.push({
                    img: image.url,
                    alt: image.altText ?? undefined,
                  });
                }
              }
            }
          }
          break;
        }

        case "illustration":
          productToStore.illustration = getSingleImage(component, "raw");
          break;

        case "related-products":
          productToStore.relatedProducts =
            getItemRelations(component).map(toPacket);
          break;

        case "pendel-cta":
          productToStore.pendelCta = getBoolean(component);
          break;

        case "level-filter":
          productToStore.levelFilter = getSelections(component)
            .map((selection) => LEVEL_FILTERS.find((lf) => lf === selection))
            .filter(truthyAndDistinct)[0];
          break;
      }
    }
  }

  if (!template) {
    if (productToStore.cover || productToStore.description) {
      template = "standard";
    } else if (
      productToStore.relatedProducts.length ||
      productToStore.illustration
    ) {
      template = "pluss";
    } else if (productToStore.heroCards) {
      template = productToStore.heroCards.mascotImages.length
        ? "grunnskole"
        : "pluss";
    } else {
      template = "standard";
    }
  }

  if ("variants" in item && Array.isArray(item.variants)) {
    for (const variant of item.variants) {
      const cover = variant.images?.[0];
      const prices = getPrices(variant.priceVariants ?? [], variant.price);

      const productVariant: SingleProductVariant = {
        parentType: productToStore.type,
        parentTitle: productToStore.title,
        parentSlug: productToStore.slug,
        sku: variant.sku,
        isbn: variant.sku,
        isPurchasable: false,
        name: variant.name,
        discount: false,
        price: prices.default,
        tax: 0,
        priceDiscount: prices.discount,
        priceNoTax: prices.exVat,
        priceWithTax: prices.incVat,
        formats: [],
        isTrial: false,
        subscriptionsPlan: variant.subscriptionPlans
          ? handleSubscriptionPlans(variant.subscriptionPlans)
          : undefined,
        cover: cover?.url
          ? {
              img: cover.url,
              alt: cover.altText ?? "",
            }
          : undefined,
      };

      if (variant.components) {
        for (const component of variant.components) {
          if (component.content === null) {
            continue;
          }

          const id =
            component.id as Crystallize.VariantComponentId<"default-product">;

          switch (id) {
            case "background-color":
              productVariant.mainColor = getSingleLine(component);
              break;

            case "title":
              productVariant.title = getSingleLine(component);
              break;

            case "out-of-print":
              productVariant.outOfPrint = getBoolean(component);
              break;

            case "subtitle":
              productVariant.subtitle = getSingleLine(component);
              break;

            case "authors":
              productVariant.authors = getSingleLine(component);
              break;

            case "cover-image":
              productVariant.cover = {
                img: getSingleLine(component),
                alt: `Forside av ${productVariant.title} bok`,
              };
              break;

            case "release-date":
              productVariant.release = new Date(getSingleLine(component));
              break;
            case "grade":
              productVariant.grades = getEnumValues(component, GRADES, [
                "aarstrinn",
              ]).sort((a, b) => GRADES.indexOf(a) - GRADES.indexOf(b));

              productVariant.levels = productVariant.grades
                .map((g) => GRADE_TO_LEVEL[g])
                .filter(truthyAndDistinct);

              productToStore.grades ??= [];
              productToStore.grades.push(...productVariant.grades);
              productToStore.levels ??= [];
              productToStore.levels.push(...productVariant.levels);
              break;
            case "effective-vat-percent":
              productVariant.tax = getNumber(component);
              break;
            case "url-browsable-version":
              productVariant.browsableLink = getSingleLine(component);
              break;
            case "url-preview":
              productVariant.previewLink = getSingleLine(component);
              break;
            case "componenttype": {
              productVariant.biblioFormats = getEnumValues(
                component,
                BIBLIO_FORMATS,
              );

              productVariant.formats = getEnumValues(component, PRODUCT_FORMATS)
                .concat(
                  productVariant.biblioFormats.map((it) => FORMAT_MAPPING[it]),
                )
                .filter(distinct);

              productToStore.formats.push(...productVariant.formats);
              productToStore.biblioFormats.push(
                ...productVariant.biblioFormats,
              );

              break;
            }
            case "isbn": {
              const isbn = getSingleLine(component)?.trim();
              if (isbn) {
                productVariant.isbn = isbn;
              }
              break;
            }
            case "description": {
              productVariant.description = getRichText(component);
              break;
            }

            case "language": {
              productVariant.languages = getEnumValues(component, LANGUAGES);
              break;
            }

            case "subjects": {
              productVariant.subjects ??= [];
              productVariant.subjects.push(
                ...getEnumValues(component, SUBJECT_CODES),
              );
              break;
            }

            case "productform": {
              const productForm = getSingleLine(component);
              productVariant.productForm = PRODUCT_FORMS.find(
                (it) => it === productForm,
              );
              break;
            }

            case "copyright-year":
              productVariant.copyrightYear = Number(getSingleLine(component));
              break;
          }
        }
      }

      productVariant.isPurchasable =
        productVariant.formats?.includes("nettsted") ||
        productVariant.formats?.includes("unibok") ||
        false;

      productVariant.isbn ||= productVariant.sku;

      if (!productVariant.outOfPrint) {
        productToStore.variants?.push(productVariant);
      }
    }
  }

  // TODO: Replace all of these with sets?
  productToStore.levels = productToStore.levels?.filter(distinct);
  productToStore.grades = productToStore.grades?.filter(distinct);
  productToStore.formats = productToStore.formats.filter(distinct);
  productToStore.biblioFormats = productToStore.biblioFormats.filter(distinct);

  return {
    ...productToStore,
    template,
  };
}

const LANGUAGE_LABELS: Record<Language, string> = {
  nob: "Bokmål",
  nno: "Nynorsk",
  eng: "Engelsk",
  ger: "Tysk",
  no2: "Bokmål, Nynorsk",
};

export function languages(variant: SingleProductVariant) {
  return (
    variant.languages
      ?.map((it) => LANGUAGE_LABELS[it])
      .filter(truthyAndDistinct)
      .join("/") ?? ""
  );
}
