import { type Document } from "~/types/document";
import type { SingleProductVariant } from "~/types/single-product";

export function byFirstDate(direction: "asc" | "desc" = "asc") {
  return <T extends { dates: (Date | string)[] }>(
    { dates: [a] }: T,
    { dates: [b] }: T,
  ) => {
    const aDate = a ? new Date(a) : undefined;
    const bDate = b ? new Date(b) : undefined;

    if (!aDate || !bDate) {
      return 0;
    }

    return direction === "asc"
      ? aDate.getTime() - bDate.getTime()
      : bDate.getTime() - aDate.getTime();
  };
}

export function byPublished(direction: "asc" | "desc" = "desc") {
  return <T extends Document>(a: T, b: T) => {
    const aDate = a.publishedAt ?? a.updatedAt ?? a.createdAt;
    const bDate = b.publishedAt ?? b.updatedAt ?? b.createdAt;

    if (!aDate || !bDate) {
      return 0;
    }

    return direction === "asc"
      ? new Date(aDate).getTime() - new Date(bDate).getTime()
      : new Date(bDate).getTime() - new Date(aDate).getTime();
  };
}

type ScoreFn<T> = (t: T) => number;

type Comparator<T> = (a: T, b: T) => number;

/**
 * Sort by multiple comparators, returning the first non-zero result.
 *
 * @param comparators - The comparators to chain.
 */
export function chainedSort<T>(...comparators: Comparator<T>[]) {
  return (a: T, b: T) => {
    for (const comparator of comparators) {
      const result = comparator(a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };
}

/**
 * Convert a score function to a comparator.
 * @param scoreFn
 */
export function toComparator<T>(scoreFn: ScoreFn<T>): Comparator<T> {
  return (a: T, b: T) => scoreFn(a) - scoreFn(b);
}

export const productVariants = chainedSort(
  toComparator(getHighestGrade),
  toComparator(getBiblioFormatScore),
  (a, b) => a.title?.localeCompare(b.title ?? "") ?? 0,
  (a, b) => a.subtitle?.localeCompare(b.subtitle ?? "") ?? 0,
  toComparator(getLanguageScore),
);

function getHighestGrade(variant: SingleProductVariant) {
  return variant.grades
    ? Math.max(...variant.grades.map((g) => GRADES.indexOf(g)))
    : 0;
}

function getBiblioFormatScore(variant: SingleProductVariant) {
  return (
    BIBLIO_FORMATS.findIndex((it) => variant.biblioFormats?.includes(it)) ?? 0
  );
}

function getLanguageScore(variant: SingleProductVariant) {
  return variant.languages?.some((it) => it === "nob" || it === "no2") ? -1 : 1;
}
