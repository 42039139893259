<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    fill?: string;
    color?: string;
  }>(),
  {
    fill: "white",
    color: undefined,
  },
);

const reverseColor = computed(
  () => props.color ?? (props.fill === "white" ? "black" : "white"),
);
</script>

<template>
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 24.9831C30.5228 19.4634 30.5228 10.5141 25 4.99439C19.4772 -0.52535 10.5228 -0.525344 5 4.9944C-0.522849 10.5141 -0.522849 19.4634 5 24.9831C10.5228 30.5029 19.4772 30.5029 25 24.9831Z"
      :fill="fill"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.04099 12.0114H10.1983V22.5613H7.04099V12.0114ZM8.60029 6.65894C9.65271 6.65894 10.51 7.51203 10.51 8.55932C10.51 9.60661 9.65271 10.4597 8.60029 10.4597C7.54786 10.4597 6.72925 9.60661 6.72925 8.55932C6.72925 7.51203 7.54786 6.65894 8.60029 6.65894ZM12.0693 12.0114H15.1873V13.4856C15.8501 12.5158 16.9411 11.8954 18.1887 11.8954H19.046C21.0336 11.8954 22.6316 13.5246 22.6316 15.4635V22.5613H19.5136V21.5915V16.8595C19.4743 15.6571 18.6563 14.7264 17.448 14.7264C16.2397 14.7264 15.2266 15.6571 15.1873 16.8595V22.5613H12.0693V12.0114Z"
      :fill="reverseColor"
    />
  </svg>
</template>
